import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Link as UiLink,
} from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import AdoptPagePanel from "./AdoptPagePanel";
import { useCryptopaka } from "../../hooks";
import PakaImg from "../PakaImg";
import { config } from "../../config";

function NoPakaNotice() {
  return (
    <Card variant="outlined">
      <CardHeader
        title="You have not adopted any Paka yet!"
        subheader="They are waiting for you! UwU"
      />
      <CardActions>
        <Button
          color="primary"
          variant="contained"
          component={Link}
          to="/adopt"
        >
          Go adopt
        </Button>
      </CardActions>
    </Card>
  );
}

function V2List() {
  const { v2Contract } = useCryptopaka();
  const { account } = useWeb3React();
  const [pakaCount, setPakaCount] = useState(-1);
  const [pakas, setPakas] = useState([] as bigint[]);

  useEffect(() => {
    if (v2Contract && account) {
      v2Contract
        .balanceOf(account)
        .then((count) => setPakaCount(count.toNumber()));
    }
  }, [v2Contract, account]);

  useEffect(() => {
    async function fetchPakas() {
      if (v2Contract && account && pakaCount > 0) {
        const pakas = await Promise.all(
          Array.from(Array(pakaCount), (_, i) =>
            v2Contract.tokenOfOwnerByIndex(account, i),
          ),
        );
        setPakas(pakas.map((pakaId) => pakaId.toBigInt()));
      }
    }
    fetchPakas();
  }, [v2Contract, account, pakaCount]);

  if (!account || !v2Contract) {
    return null;
  }

  return (
    <>
      {pakaCount < 0 ? null : pakaCount === 0 ? (
        <NoPakaNotice />
      ) : (
        <Card variant="outlined">
          <CardHeader title="Your Pakas" />
          <CardContent>
            <Box textAlign="center">
              {pakas.map((pakaId) => (
                <Box key={pakaId.toString()} display="inline-block">
                  <PakaImg
                    tokenId={pakaId}
                    size={4}
                    style={{ display: "block" }}
                  />
                  {"#" + pakaId}
                </Box>
              ))}
            </Box>
          </CardContent>
        </Card>
      )}
    </>
  );
}

export default function PlazaPage() {
  return (
    <>
      <AdoptPagePanel />
      <Box my="16px">
        <Card variant="outlined">
          <CardHeader
            title="Plaza is still under construction!"
            subheader={
              <>
                Meanwhile, you can still view, buy and sell Cryptopakas on{" "}
                <UiLink
                  rel="noopener noreferrer"
                  target="_blank"
                  href={config.openseaUrl}
                >
                  OpenSea
                </UiLink>
                .
              </>
            }
          />
        </Card>
      </Box>
      <V2List />
    </>
  );
}
