import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import {
  useAppDispatch,
  useAppSelector,
  useInterval,
  useSearchSeed,
} from "../../hooks";
import { ScannerWorker, scan } from "../../scanner";
import AdoptPagePanel from "./AdoptPagePanel";
import type { AdoptPageChildrenProps } from "./AdoptPage";
import { setThreadCount } from "../../store/reducers/adopt";

import "./AdoptPageFind.css";

function ThreadSelect() {
  const threadCount = useAppSelector((state) => state.adopt.threadCount);
  const dispath = useAppDispatch();
  const maxThreads = navigator.hardwareConcurrency || 4;

  return (
    <Box mb="32px">
      <FormControl variant="outlined">
        <InputLabel>Scanning power</InputLabel>
        <Select
          value={threadCount}
          onChange={(e) => dispath(setThreadCount(Number(e.target.value)))}
          label="Number of thread"
        >
          {Array.from(Array(maxThreads), (_, i) => (
            <MenuItem value={i + 1} key={i + 1}>
              Use {i + 1} CPU {i ? "threads" : "thread"}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

function ScanInvoker({
  isScanning,
  searchSeed,
  startScan,
}: {
  isScanning: boolean;
  searchSeed?: string;
  startScan: (seed: string) => void;
}) {
  if (!searchSeed || isScanning) {
    return (
      <Button color="primary" variant="outlined" disabled>
        {isScanning ? "Scanning..." : "Loading..."}
      </Button>
    );
  }

  return (
    <Button
      color="primary"
      variant="contained"
      onClick={() => startScan(searchSeed)}
    >
      Start scanning
    </Button>
  );
}

export default function AdoptPageFind({
  setResultSeed,
}: AdoptPageChildrenProps) {
  const threadCount = useAppSelector((state) => state.adopt.threadCount);
  const [isScanning, setIsScanning] = useState(false);
  const [workers] = useState<ScannerWorker[]>([]);
  const searchSeed = useSearchSeed();

  async function startScan(seed: string) {
    setIsScanning(true);
    console.info("[scan] starts");
    const tasks = Array.from(Array(threadCount), () => {
      const worker = new ScannerWorker();
      workers.push(worker);
      return scan(worker, seed);
    });
    const retSeed = await Promise.any(tasks);
    setResultSeed(retSeed);
    console.info("[scan] found " + retSeed);
    workers.forEach((w) => {
      w.terminate();
    });
    console.info("[scan] cleaned up workers");
    setIsScanning(false);
  }

  useEffect(() => {
    return () => {
      workers.forEach((w) => {
        w.terminate();
      });
      console.info("[scan] cleaned up workers");
    };
  }, [workers]);

  return (
    <>
      <AdoptPagePanel />
      <Box mt="16px">
        <Typography>
          Cryptopakas are born in the void and scattered around the universe. We
          have prepared a state-of-the-art Paka Scanner for you to find them.
          The process is very similar to crypto mining, so the timing depends on
          the power of your CPU and your luck.
        </Typography>
      </Box>
      <Box mt="16px">
        <Typography>
          You can configure how many CPU threads you want your Paka Scanner to
          use, we recommend using one less than the maximum. It can take
          anywhere from a few seconds to a few minutes to find a Paka.
        </Typography>
      </Box>
      <Box mt="16px">
        <Typography>Please be patient!</Typography>
      </Box>
      <Box mt="16px" textAlign="center">
        <ThreadSelect />
        <ScanInvoker
          isScanning={isScanning}
          searchSeed={searchSeed}
          startScan={startScan}
        />
      </Box>
      <ScanAnimationDialog open={isScanning} />
    </>
  );
}

function genHexStringPart() {
  const str = Math.floor(Math.random() * 4294967296).toString(16);
  return "0".repeat(8 - str.length) + str;
}

function genHexString() {
  return `0x${genHexStringPart()}...${genHexStringPart()}`;
}

function ScanAnimationDialog({ open }: { open: boolean }) {
  const [randHex, setRandHex] = useState(genHexString());

  useInterval(() => {
    setRandHex(genHexString());
  }, 30);

  return (
    <Dialog open={open}>
      <DialogTitle
        style={{
          backgroundColor: "black",
          color: "#f5f5f7",
          textAlign: "center",
        }}
      >
        Scanning
      </DialogTitle>
      <DialogContent style={{ backgroundColor: "black", color: "#f5f5f7" }}>
        <img
          className="adoptpage__scan-animation"
          src="planet.gif"
          alt="Scanning..."
        />
        <p style={{ textAlign: "center" }}>{randHex}</p>
      </DialogContent>
    </Dialog>
  );
}
