const development = {
  supportedChainIds: [1, 31337],
  v1Address: "0x5fbdb2315678afecb367f032d93f642f64180aa3", // deployed on first block
  v2Address: "0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512",
  openseaUrl: "https://opensea.io/collection/cryptopaka",
};

const production = {
  supportedChainIds: [1],
  v1Address: "0x4bed1ac07d1fb88509d84e08c6dc28783648bcff",
  v2Address: "0xf349f65802483423BA3C9899d5dC39abd42a8963",
  openseaUrl: "https://opensea.io/collection/cryptopaka",
};

/**
 * See {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/freeze}
 */
function deepFreeze(object: { [k: string]: any }) {
  const propNames = Object.getOwnPropertyNames(object);
  for (const name of propNames) {
    const value = object[name];
    if (value && typeof value === "object") {
      deepFreeze(value);
    }
  }
  Object.freeze(object);
}

export const config: typeof development =
  process.env.NODE_ENV === "production" ? production : development;
deepFreeze(config);
