import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  AppBar,
  Tabs,
  Tab,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { routes, stripTrailingSlash } from "../routes";

import "./TopBar.css";

export default function TopBar() {
  const { pathname } = useLocation();

  const isXs = useMediaQuery((theme: any) => theme.breakpoints.down("xs"));
  const isSm = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  const tabStyle = isSm ? { width: "72px", minWidth: "72px" } : undefined;
  return (
    <AppBar position="static" color="transparent" className="topbar">
      <Tabs value={stripTrailingSlash(pathname)}>
        {routes
          .filter((r) => !r.hidden)
          .reverse()
          .map((route) => (
            <Tab
              label={route.key}
              value={route.path}
              key={route.key}
              style={tabStyle}
              component={Link}
              to={route.path}
            />
          ))}
      </Tabs>
      {!isXs && <Typography className="topbar__title">CRYPTOPAKA</Typography>}
    </AppBar>
  );
}
