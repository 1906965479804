import { Box } from "@material-ui/core";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import TopBar from "./TopBar";
import { routes, stripTrailingSlash } from "../routes";

function MainInterface() {
  const { pathname } = useLocation();
  return (
    <>
      <TopBar />
      <Box
        display="flex"
        flexDirection="column"
        width="95%"
        maxWidth="832px"
        justifyContent="center"
        mx="auto"
      >
        <Switch>
          <Redirect from="/:url*(/+)" to={stripTrailingSlash(pathname)} />
          {routes.map((route) => (
            <Route
              key={route.key}
              path={route.path}
              component={route.component}
              exact={route.exact}
            />
          ))}
          <Route>
            <Redirect to="/" />
          </Route>
        </Switch>
      </Box>
    </>
  );
}

export default MainInterface;
