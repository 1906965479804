import React, { useState } from "react";
import { Stepper, Step, StepLabel } from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import AdoptPageWallet from "./AdoptPageWallet";
import AdoptPageFind from "./AdoptPageFind";
import AdoptPageFound from "./AdoptPageFound";

const steps = [
  { label: "Connect Wallet", component: AdoptPageWallet },
  { label: "Find a Paka", component: AdoptPageFind },
  { label: "Adopt It", component: AdoptPageFound },
];

export interface AdoptPageChildrenProps {
  handleNext: () => void;
  handleBack: () => void;
  resultSeed?: string;
  setResultSeed: (s: string | undefined) => void;
}

export default function AdoptPage() {
  const { account } = useWeb3React();
  const [curStep, setCurStep] = useState(0);
  const [resultSeed, setResultSeed] = useState<string | undefined>();

  const handleNext = () => {
    setCurStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setCurStep((prev) => prev - 1);
  };

  const StepComponent = steps[curStep].component;

  if (account && curStep === 0) {
    setCurStep(1);
  } else if (resultSeed && curStep === 1) {
    setCurStep(2);
  } else if (!resultSeed && curStep === 2) {
    setCurStep(1);
  }

  return (
    <>
      <Stepper activeStep={curStep} alternativeLabel>
        {steps.map(({ label }) => {
          return (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <StepComponent
        handleNext={handleNext}
        handleBack={handleBack}
        resultSeed={resultSeed}
        setResultSeed={setResultSeed}
      />
    </>
  );
}
