import React from "react";
import HomePage from "./components/views/HomePage";
import AdoptPage from "./components/views/AdoptPage";
import DebugPage from "./components/views/DebugPage";
import PlazaPage from "./components/views/PlazaPage";

export interface Route {
  key: string;
  path: string;
  exact?: boolean;
  hidden?: boolean;
  component: React.ComponentType;
}

export const routes = Object.freeze([
  {
    key: "Plaza",
    path: "/plaza",
    exact: true,
    component: PlazaPage,
  },
  {
    key: "Adopt",
    path: "/adopt",
    exact: true,
    component: AdoptPage,
  },
  {
    key: "Debug",
    path: "/debug",
    exact: true,
    hidden: true,
    component: DebugPage,
  },
  {
    key: "Home",
    path: "/",
    exact: true,
    component: HomePage,
  },
] as Route[]);

export function stripTrailingSlash(path: string) {
  return path.length > 1 && path.endsWith("/") ? path.slice(0, -1) : path;
}
