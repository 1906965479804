import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { ContractTransaction, ethers } from "ethers";
import { useCryptopaka, useWallet } from "../../hooks";
import PakaTestViewer from "./DebugPagePakaViewer";

function callMethod<T extends any[]>(
  method: (...args: T) => Promise<ContractTransaction>,
  ...args: T
) {
  return method(...args)
    .then((tx) => {
      alert("Transaction recorded: " + tx.hash + "\n" + tx.toString());
    })
    .catch((e) => {
      alert(e.data?.message ? e.data.message : e.message);
    });
}

export default function DebugPage() {
  const { v1Contract, v2Contract } = useCryptopaka();
  const { connect, account } = useWallet();

  if (!account) {
    return (
      <>
        <Button variant="outlined" onClick={connect}>
          Connect Wallet
        </Button>
        <PakaTestViewer />
      </>
    );
  }

  if (!v1Contract || !v2Contract) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box>
      <Button variant="outlined" onClick={() => callMethod(v1Contract.unpause)}>
        Unpause v1
      </Button>
      <Button variant="outlined" onClick={() => callMethod(v1Contract.pause)}>
        Pause v1
      </Button>
      <Button
        variant="outlined"
        onClick={() => callMethod(v2Contract.unpause)}
      >
        Unpause v2
      </Button>
      <Button variant="outlined" onClick={() => callMethod(v2Contract.pause)}>
        Pause v2
      </Button>
      <Button variant="contained" onClick={() => alert("DUMB")}>
        Transfer v1 to v2
      </Button>
      <Button
        variant="outlined"
        onClick={() =>
          callMethod(
            v1Contract.mint,
            "0x8efbe39735fc3e983e70bab9f40d623c21edd75dd2d8482c9ad99b1d606f13c3",
            { value: 10000000000000 },
          )
        }
      >
        v1 local adopt
      </Button>

      <Button
        variant="outlined"
        onClick={() => {
          const fnName = prompt("Function name: ") as any;
          const arg = prompt("arg?") as any;
          callMethod((v1Contract as any)[fnName], arg ?? undefined);
        }}
      >
        v1 function prompt
      </Button>

      <Button
        variant="outlined"
        onClick={() => {
          const id = prompt("Id: ") as any;
          callMethod(v1Contract.mintGenesis, id);
        }}
      >
        v1 genesis paka
      </Button>

      <Button
        variant="outlined"
        onClick={() => {
          const fnName = prompt("Function name: ") as any;
          callMethod((v2Contract as any)[fnName]);
        }}
      >
        v2 function prompt
      </Button>

      <PakaTestViewer />
    </Box>
  );
}
