import { BigNumber } from "@ethersproject/bignumber";
import type { BigNumberish } from "ethers";
import { arrayify, concat, keccak256 } from "ethers/lib/utils";
import { Cryptopaka, CryptopakaV2 } from "../contracts";

export function seedToId(tokenSeed: string, searchSeed: string): BigNumber {
  const seed = concat([tokenSeed, searchSeed]);
  const hash = keccak256(seed);
  console.log("hash", hash);
  return BigNumber.from(arrayify(hash).slice(0, 5));
}

export async function isAdopted(
  contract: Cryptopaka | CryptopakaV2,
  tokenId: BigNumberish,
) {
  try {
    await contract.ownerOf(tokenId);
    return true;
  } catch {
    return false;
  }
}
