import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const adoptSlice = createSlice({
  name: "adopt",
  initialState: {
    threadCount: 2,
  },
  reducers: {
    setThreadCount: (state, action: PayloadAction<number>) => {
      state.threadCount = action.payload;
    },
  },
});

export const { setThreadCount } = adoptSlice.actions;

export default adoptSlice.reducer;
