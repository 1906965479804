import { useState } from "react";
import { BigNumber, ethers } from "ethers";
import {
  Button,
  Card,
  CardContent,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { useCryptopaka, useInterval, useWallet } from "../../hooks";

function formatDecimal(d: string): string {
  return `${parseFloat(parseFloat(d).toPrecision(3))}`;
}

function shortenAccount(acc: string): string {
  return `${acc.substring(0, 6)} . . . ${acc.slice(-4)}`;
}

export default function AdoptPagePanel() {
  const { v2Contract } = useCryptopaka();
  const { account, connect } = useWallet();
  const isSm = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  const [price, setPrice] = useState(BigNumber.from(0));
  const [pakaCount, setPakaCount] = useState(BigNumber.from(-1));

  // TODO: use timeout instead
  useInterval(() => {
    if (v2Contract) {
      v2Contract.price().then((p) => setPrice(p));
      v2Contract.totalSupply().then((e) => setPakaCount(e));
    }
  }, 5000);

  if (!v2Contract || !account) {
    return (
      <Button variant="outlined" onClick={connect}>
        Connect Wallet
      </Button>
    );
  }

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography>
          Your wallet:{" "}
          {isSm ? (
            <Tooltip title={account}>
              <span>{shortenAccount(account)}</span>
            </Tooltip>
          ) : (
            account
          )}
        </Typography>
        <Typography>
          Flat adoption fee:{" "}
          {price.lte(0)
            ? "loading"
            : `${formatDecimal(ethers.utils.formatEther(price))} ETH + gas`}
        </Typography>
        <Typography>
          Number of Pakas adopted:{" "}
          {pakaCount.lt(0) ? "loading" : `${pakaCount.add(16)}/6969`}
          {/* TODO: update genesis paka count */}
        </Typography>
      </CardContent>
    </Card>
  );
}
