import React, { useState } from "react";
import { Box, FormControl, Grid, Slider, Typography } from "@material-ui/core";
import PakaImg from "../PakaImg";

function IntegerSlider({
  label,
  count,
  value,
  onChange,
}: {
  label: string;
  count: number;
  value: number;
  onChange: (newVal: number) => void;
}) {
  return (
    <Grid container spacing={2}>
      <Grid item>{label}</Grid>
      <Grid item xs>
        <Slider
          value={value}
          onChange={(_, val) => onChange(val as number)}
          step={1}
          marks={count <= 16}
          min={0}
          max={count - 1}
        />
      </Grid>
      <Grid item>{value}</Grid>
    </Grid>
  );
}

function arrToBigint(buf: Uint8Array) {
  const hex: string[] = [];

  buf.forEach(function (i) {
    var h = i.toString(16);
    if (h.length % 2) {
      h = "0" + h;
    }
    hex.push(h);
  });

  return BigInt("0x" + hex.join(""));
}

export default function PakaViewer() {
  const [core, setCore] = useState(0);
  const [r, setR] = useState(0);
  const [g, setG] = useState(0);
  const [b, setB] = useState(0);

  const [eb, setEb] = useState(0);
  const [face, setFace] = useState(0);
  const [neck, setNeck] = useState(0);
  const [eye, setEye] = useState(0);
  const [hair, setHair] = useState(0);
  const [hat, setHat] = useState(0);

  const arr = new Uint8Array([
    r,
    g,
    b,
    (core << 4) + (eb << 2) + face,
    (neck << 6) + (eye << 4) + (hair << 2) + hat,
  ]);
  const id = arrToBigint(arr);
  return (
    <Box width={400}>
      <FormControl fullWidth margin="normal">
        <IntegerSlider label="R" value={r} onChange={setR} count={256} />
        <IntegerSlider label="G" value={g} onChange={setG} count={256} />
        <IntegerSlider label="B" value={b} onChange={setB} count={256} />
        <IntegerSlider
          label="Core"
          value={core}
          onChange={setCore}
          count={16}
        />
        <IntegerSlider label="Eyebow" value={eb} onChange={setEb} count={4} />
        <IntegerSlider label="Face" value={face} onChange={setFace} count={4} />
        <IntegerSlider label="Neck" value={neck} onChange={setNeck} count={4} />
        <IntegerSlider label="Eye" value={eye} onChange={setEye} count={4} />
        <IntegerSlider label="Hair" value={hair} onChange={setHair} count={4} />
        <IntegerSlider label="Hat" value={hat} onChange={setHat} count={4} />
      </FormControl>
      <Typography>{id.toString()}</Typography>
      <PakaImg tokenId={id} />
    </Box>
  );
}
