import { parse } from "cryptopaka-parser";
import React, { useState, useMemo } from "react";
import { useInterval } from "../hooks";

function generatePakaFrames(tokenId: bigint, size: number) {
  const data = parse(tokenId);
  const ret = [];
  for (let k = 0; k < data.length; k++) {
    const frame = data[k];
    const canvas = document.createElement("canvas");
    canvas.width = size * frame[0].length;
    canvas.height = size * frame.length;
    const ctx = canvas.getContext("2d")!;

    for (let i = 0; i < frame.length; i++) {
      for (let j = 0; j < frame[i].length; j++) {
        const color = frame[i][j];
        if (color) {
          ctx.fillStyle = color;
          ctx.fillRect(j * size, i * size, size, size);
        }
      }
    }
    ret[k] = canvas.toDataURL();
  }
  return ret;
}

function usePakaImgSrc(tokenId: bigint, size = 10) {
  const [src, setSrc] = useState("");
  const [frameIdx, setFrameIdx] = useState(0);
  const frames = useMemo(
    () => generatePakaFrames(tokenId, size),
    [tokenId, size],
  );
  const frameIdxs = [0, 1, 2, 1];

  useInterval(() => {
    setSrc(frames[frameIdxs[frameIdx]]);
    setFrameIdx((frameIdx + 1) % frameIdxs.length);
  }, 250);

  return src;
}

export default function PakaImg({
  tokenId,
  style,
  size,
}: {
  tokenId: bigint;
  style?: React.CSSProperties;
  size?: number;
}) {
  const src = usePakaImgSrc(tokenId, size);
  return <img alt={`Paka #${tokenId}`} src={src} style={style} />;
}
