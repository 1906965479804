import { Button, Link, Typography, Box } from "@material-ui/core";
import { useWallet } from "../../hooks";

export default function AdoptPageWallet() {
  const { connect } = useWallet();

  return (
    <>
      <Typography>
        You need to first connect to your wallet to interact with the
        blockchain. For now, we only support MetaMask.
      </Typography>
      {window.ethereum && !window.ethereum.isMetaMask && (
        <Typography>
          We have detected a different wallet than MetaMask, you can try to
          connect it.
        </Typography>
      )}
      {!window.ethereum && (
        <Typography>
          We are unable to detect a wallet. You can try to install MetaMask{" "}
          <Link
            rel="noopener noreferrer"
            target="_blank"
            href="https://metamask.io/download.html"
          >
            here
          </Link>
          .
        </Typography>
      )}
      {window.ethereum && (
        <Box mt="16px" textAlign="center">
          <Button color="primary" variant="outlined" onClick={connect}>
            Connect Wallet
          </Button>
        </Box>
      )}
    </>
  );
}
