import React from "react";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  Link as UiLink,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import "./HomePage.css";
import { Link } from "react-router-dom";
import { config } from "../../config";

function FaqTitle({ children }: { children: React.ReactNode }) {
  return (
    <Typography variant="h6" className="homepage__title2">
      {children}
    </Typography>
  );
}

export default function HomePage() {
  return (
    <div className="homepage">
      <img id="homepage__demo" src="demo.gif" alt="Demo" />
      <div style={{ textAlign: "center" }}>
        <Typography className="homepage__body" style={{ margin: "20px 0" }}>
          Cryptopaka is a collection of 6,969 procedurally generated animated
          Paka NFTs living on the Ethereum blockchain. Your Paka also makes you
          a Paka Keeper that grants you access to exclusive perks that can be
          unlocked through our roadmap.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="large"
          component={Link}
          to="/adopt"
        >
          Adopt now
        </Button>
        <Typography variant="h5" className="homepage__title">
          Community driven
        </Typography>
        <Typography className="homepage__body">
          Instead of selling all of the Pakas, we have given people the
          opportunity to "mine" Pakas themselves. There are over one trillion
          unique Pakas in the wild. Since only 6,969 of them can be added on the
          blockchain, our Paka Keepers can choose whether they want specific
          Pakas to become part of the collection or not. They will shape the
          collection and the rarity of every Paka.
        </Typography>
        <Typography variant="h5" className="homepage__title">
          Fair distribution
        </Typography>
        <Typography className="homepage__body">
          There are no bonding curves here. Adopting a Paka will always cost
          0.08 ETH. We have reserve some Pakas to be used for giveaways and
          event rewards through.
        </Typography>
        <Typography variant="h5" className="homepage__title">
          Roadmap
        </Typography>

        <Typography className="homepage__body">
          These are the goals we have set up for ourselves. We will start
          working on a goal once we hit its target adoption percentage.
        </Typography>
        <Table style={{ marginTop: "20px" }}>
          <TableBody>
            <TableRow>
              <TableCell>30%</TableCell>
              <TableCell align="left">
                Metadata reveal, you will see all the traits your Paka has on
                compatible plateform such as OpenSea.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>40%</TableCell>
              <TableCell align="left">
                Four genesis Paka are airdropped to random Paka Keepers.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>50%</TableCell>
              <TableCell align="left">
                Partnership with other NFT projects and artists to host
                giveaways exclusive to our Paka Keepers.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>60%</TableCell>
              <TableCell align="left">
                Paka Keeper exclusive merch store featuring limited edition
                goodies.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>69%</TableCell>
              <TableCell align="left">
                Paka Keeper exclusive online forum to share thoughts.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>80%</TableCell>
              <TableCell align="left">
                Puzzle quests with a price pool of 20 ETH and eight genesis
                Paka.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>90%</TableCell>
              <TableCell align="left">
                You can milk your pakas to pay your rent.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>99.8%</TableCell>
              <TableCell align="left">
                Auction of four final genesis Paka.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>100%</TableCell>
              <TableCell align="left">
                Activate the portal to Pakaland that unlock NFT breeding to
                share the cuteness with the world!
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Button
          variant="contained"
          color="primary"
          size="large"
          component={Link}
          to="/adopt"
          style={{ marginTop: "40px" }}
        >
          Adopt now
        </Button>
        <Typography variant="h5" className="homepage__title">
          FAQ
        </Typography>
        <FaqTitle>Why Cryptopakas ?</FaqTitle>
        <Typography className="homepage__body2">
          There are countless cat-related NFT projects like MoonCatRescue and
          CryptoKitties. However, there are not many about alpacas. We want to
          share their cuteness with the world.
        </Typography>
        <FaqTitle>What can I do with my pakas ?</FaqTitle>
        <Typography className="homepage__body2">
          You own your pakas fully and can do anything with them. You are given
          worldwide, royaulty-free, perpetual (as long as you still own the
          NFTs) rights over your NFTs to do whatever you want with them without
          any restriction.
        </Typography>

        <FaqTitle>Where is the contract deployed on Ethereum ?</FaqTitle>
        <Typography className="homepage__body2">
          You can find the verified contract at{" "}
          <UiLink
            rel="noopener noreferrer"
            target="_blank"
            href={`https://etherscan.io/address/${config.v2Address}`}
            style={{ wordBreak: "break-all" }}
          >
            {config.v2Address}
          </UiLink>
          .
        </Typography>

        <FaqTitle>
          How can I verify that I own what is shown on the website ?
        </FaqTitle>
        <Typography className="homepage__body2">
          The parser is available{" "}
          <UiLink rel="noopener noreferrer" target="_blank" href="parser.js">
            here
          </UiLink>
          . You can validate the sha256 of the parser with what is stored on the
          blockchain yourself.
        </Typography>
      </div>
    </div>
  );
}
