import { SvgIcon } from "@material-ui/core";

export function MoonCatIcon() {
  return (
    <SvgIcon viewBox="0 0 85 110">
      <rect x="0" y="65" width="5" height="5" fill="#333300" />
      <rect x="0" y="70" width="5" height="5" fill="#333300" />
      <rect x="0" y="75" width="5" height="5" fill="#333300" />
      <rect x="0" y="80" width="5" height="5" fill="#333300" />
      <rect x="0" y="85" width="5" height="5" fill="#333300" />
      <rect x="5" y="55" width="5" height="5" fill="#333300" />
      <rect x="5" y="60" width="5" height="5" fill="#333300" />
      <rect x="5" y="65" width="5" height="5" fill="#333300" />
      <rect x="5" y="70" width="5" height="5" fill="#e6e600" />
      <rect x="5" y="75" width="5" height="5" fill="#e6e600" />
      <rect x="5" y="80" width="5" height="5" fill="#e6e600" />
      <rect x="5" y="85" width="5" height="5" fill="#333300" />
      <rect x="5" y="90" width="5" height="5" fill="#333300" />
      <rect x="10" y="55" width="5" height="5" fill="#333300" />
      <rect x="10" y="60" width="5" height="5" fill="#e6e600" />
      <rect x="10" y="65" width="5" height="5" fill="#e6e600" />
      <rect x="10" y="70" width="5" height="5" fill="#333300" />
      <rect x="10" y="75" width="5" height="5" fill="#333300" />
      <rect x="10" y="80" width="5" height="5" fill="#e6e600" />
      <rect x="10" y="85" width="5" height="5" fill="#e6e600" />
      <rect x="10" y="90" width="5" height="5" fill="#333300" />
      <rect x="10" y="95" width="5" height="5" fill="#333300" />
      <rect x="15" y="50" width="5" height="5" fill="#333300" />
      <rect x="15" y="55" width="5" height="5" fill="#e6e600" />
      <rect x="15" y="60" width="5" height="5" fill="#333300" />
      <rect x="15" y="65" width="5" height="5" fill="#333300" />
      <rect x="15" y="70" width="5" height="5" fill="#333300" />
      <rect x="15" y="75" width="5" height="5" fill="#333300" />
      <rect x="15" y="80" width="5" height="5" fill="#333300" />
      <rect x="15" y="85" width="5" height="5" fill="#333300" />
      <rect x="15" y="90" width="5" height="5" fill="#e6e600" />
      <rect x="15" y="95" width="5" height="5" fill="#333300" />
      <rect x="20" y="50" width="5" height="5" fill="#333300" />
      <rect x="20" y="55" width="5" height="5" fill="#333300" />
      <rect x="20" y="60" width="5" height="5" fill="#333300" />
      <rect x="20" y="65" width="5" height="5" fill="#e6e600" />
      <rect x="20" y="70" width="5" height="5" fill="#e6e600" />
      <rect x="20" y="75" width="5" height="5" fill="#e6e600" />
      <rect x="20" y="80" width="5" height="5" fill="#e6e600" />
      <rect x="20" y="85" width="5" height="5" fill="#e6e600" />
      <rect x="20" y="90" width="5" height="5" fill="#e6e600" />
      <rect x="20" y="95" width="5" height="5" fill="#333300" />
      <rect x="20" y="100" width="5" height="5" fill="#333300" />
      <rect x="20" y="105" width="5" height="5" fill="#333300" />
      <rect x="25" y="15" width="5" height="5" fill="#333300" />
      <rect x="25" y="20" width="5" height="5" fill="#333300" />
      <rect x="25" y="25" width="5" height="5" fill="#333300" />
      <rect x="25" y="30" width="5" height="5" fill="#333300" />
      <rect x="25" y="35" width="5" height="5" fill="#333300" />
      <rect x="25" y="40" width="5" height="5" fill="#333300" />
      <rect x="25" y="45" width="5" height="5" fill="#333300" />
      <rect x="25" y="50" width="5" height="5" fill="#333300" />
      <rect x="25" y="55" width="5" height="5" fill="#e6e600" />
      <rect x="25" y="60" width="5" height="5" fill="#e6e600" />
      <rect x="25" y="65" width="5" height="5" fill="#e6e600" />
      <rect x="25" y="70" width="5" height="5" fill="#e6e600" />
      <rect x="25" y="75" width="5" height="5" fill="#e6e600" />
      <rect x="25" y="80" width="5" height="5" fill="#333300" />
      <rect x="25" y="85" width="5" height="5" fill="#e6e600" />
      <rect x="25" y="90" width="5" height="5" fill="#e6e600" />
      <rect x="25" y="95" width="5" height="5" fill="#e6e600" />
      <rect x="25" y="100" width="5" height="5" fill="#ffbb99" />
      <rect x="25" y="105" width="5" height="5" fill="#333300" />
      <rect x="30" y="0" width="5" height="5" fill="#333300" />
      <rect x="30" y="5" width="5" height="5" fill="#333300" />
      <rect x="30" y="10" width="5" height="5" fill="#333300" />
      <rect x="30" y="15" width="5" height="5" fill="#333300" />
      <rect x="30" y="20" width="5" height="5" fill="#e6e600" />
      <rect x="30" y="25" width="5" height="5" fill="#e6e600" />
      <rect x="30" y="30" width="5" height="5" fill="#e6e600" />
      <rect x="30" y="35" width="5" height="5" fill="#e6e600" />
      <rect x="30" y="40" width="5" height="5" fill="#e6e600" />
      <rect x="30" y="45" width="5" height="5" fill="#333300" />
      <rect x="30" y="50" width="5" height="5" fill="#e6e600" />
      <rect x="30" y="55" width="5" height="5" fill="#e6e600" />
      <rect x="30" y="60" width="5" height="5" fill="#e6e600" />
      <rect x="30" y="65" width="5" height="5" fill="#e6e600" />
      <rect x="30" y="70" width="5" height="5" fill="#e6e600" />
      <rect x="30" y="75" width="5" height="5" fill="#e6e600" />
      <rect x="30" y="80" width="5" height="5" fill="#333300" />
      <rect x="30" y="85" width="5" height="5" fill="#e6e600" />
      <rect x="30" y="90" width="5" height="5" fill="#e6e600" />
      <rect x="30" y="95" width="5" height="5" fill="#e6e600" />
      <rect x="30" y="100" width="5" height="5" fill="#333300" />
      <rect x="30" y="105" width="5" height="5" fill="#333300" />
      <rect x="35" y="0" width="5" height="5" fill="#333300" />
      <rect x="35" y="5" width="5" height="5" fill="#e6e600" />
      <rect x="35" y="10" width="5" height="5" fill="#ffbb99" />
      <rect x="35" y="15" width="5" height="5" fill="#e6e600" />
      <rect x="35" y="20" width="5" height="5" fill="#e6e600" />
      <rect x="35" y="25" width="5" height="5" fill="#e6e600" />
      <rect x="35" y="30" width="5" height="5" fill="#e6e600" />
      <rect x="35" y="35" width="5" height="5" fill="#e6e600" />
      <rect x="35" y="40" width="5" height="5" fill="#e6e600" />
      <rect x="35" y="45" width="5" height="5" fill="#e6e600" />
      <rect x="35" y="50" width="5" height="5" fill="#333300" />
      <rect x="35" y="55" width="5" height="5" fill="#e6e600" />
      <rect x="35" y="60" width="5" height="5" fill="#e6e600" />
      <rect x="35" y="65" width="5" height="5" fill="#e6e600" />
      <rect x="35" y="70" width="5" height="5" fill="#e6e600" />
      <rect x="35" y="75" width="5" height="5" fill="#e6e600" />
      <rect x="35" y="80" width="5" height="5" fill="#333300" />
      <rect x="35" y="85" width="5" height="5" fill="#333300" />
      <rect x="35" y="90" width="5" height="5" fill="#e6e600" />
      <rect x="35" y="95" width="5" height="5" fill="#e6e600" />
      <rect x="35" y="100" width="5" height="5" fill="#333300" />
      <rect x="40" y="5" width="5" height="5" fill="#333300" />
      <rect x="40" y="10" width="5" height="5" fill="#e6e600" />
      <rect x="40" y="15" width="5" height="5" fill="#e6e600" />
      <rect x="40" y="20" width="5" height="5" fill="#e6e600" />
      <rect x="40" y="25" width="5" height="5" fill="#333300" />
      <rect x="40" y="30" width="5" height="5" fill="#e6e600" />
      <rect x="40" y="35" width="5" height="5" fill="#ffff66" />
      <rect x="40" y="40" width="5" height="5" fill="#e6e600" />
      <rect x="40" y="45" width="5" height="5" fill="#e6e600" />
      <rect x="40" y="50" width="5" height="5" fill="#333300" />
      <rect x="40" y="55" width="5" height="5" fill="#e6e600" />
      <rect x="40" y="60" width="5" height="5" fill="#e6e600" />
      <rect x="40" y="65" width="5" height="5" fill="#e6e600" />
      <rect x="40" y="70" width="5" height="5" fill="#e6e600" />
      <rect x="40" y="75" width="5" height="5" fill="#e6e600" />
      <rect x="40" y="80" width="5" height="5" fill="#e6e600" />
      <rect x="40" y="85" width="5" height="5" fill="#e6e600" />
      <rect x="40" y="90" width="5" height="5" fill="#e6e600" />
      <rect x="40" y="95" width="5" height="5" fill="#333300" />
      <rect x="40" y="100" width="5" height="5" fill="#333300" />
      <rect x="45" y="10" width="5" height="5" fill="#333300" />
      <rect x="45" y="15" width="5" height="5" fill="#e6e600" />
      <rect x="45" y="20" width="5" height="5" fill="#e6e600" />
      <rect x="45" y="25" width="5" height="5" fill="#e6e600" />
      <rect x="45" y="30" width="5" height="5" fill="#e6e600" />
      <rect x="45" y="35" width="5" height="5" fill="#e6e600" />
      <rect x="45" y="40" width="5" height="5" fill="#ffff66" />
      <rect x="45" y="45" width="5" height="5" fill="#e6e600" />
      <rect x="45" y="50" width="5" height="5" fill="#333300" />
      <rect x="45" y="55" width="5" height="5" fill="#e6e600" />
      <rect x="45" y="60" width="5" height="5" fill="#333300" />
      <rect x="45" y="65" width="5" height="5" fill="#e6e600" />
      <rect x="45" y="70" width="5" height="5" fill="#333300" />
      <rect x="45" y="75" width="5" height="5" fill="#ffff66" />
      <rect x="45" y="80" width="5" height="5" fill="#ffff66" />
      <rect x="45" y="85" width="5" height="5" fill="#ffff66" />
      <rect x="45" y="90" width="5" height="5" fill="#ffff66" />
      <rect x="45" y="95" width="5" height="5" fill="#333300" />
      <rect x="45" y="100" width="5" height="5" fill="#333300" />
      <rect x="50" y="10" width="5" height="5" fill="#333300" />
      <rect x="50" y="15" width="5" height="5" fill="#e6e600" />
      <rect x="50" y="20" width="5" height="5" fill="#e6e600" />
      <rect x="50" y="25" width="5" height="5" fill="#e6e600" />
      <rect x="50" y="30" width="5" height="5" fill="#e6e600" />
      <rect x="50" y="35" width="5" height="5" fill="#ffbb99" />
      <rect x="50" y="40" width="5" height="5" fill="#e6e600" />
      <rect x="50" y="45" width="5" height="5" fill="#e6e600" />
      <rect x="50" y="50" width="5" height="5" fill="#333300" />
      <rect x="50" y="55" width="5" height="5" fill="#e6e600" />
      <rect x="50" y="60" width="5" height="5" fill="#333300" />
      <rect x="50" y="65" width="5" height="5" fill="#ffbb99" />
      <rect x="50" y="70" width="5" height="5" fill="#333300" />
      <rect x="50" y="75" width="5" height="5" fill="#ffff66" />
      <rect x="50" y="80" width="5" height="5" fill="#ffff66" />
      <rect x="50" y="85" width="5" height="5" fill="#ffff66" />
      <rect x="50" y="90" width="5" height="5" fill="#ffff66" />
      <rect x="50" y="95" width="5" height="5" fill="#e6e600" />
      <rect x="50" y="100" width="5" height="5" fill="#ffbb99" />
      <rect x="50" y="105" width="5" height="5" fill="#333300" />
      <rect x="55" y="10" width="5" height="5" fill="#333300" />
      <rect x="55" y="15" width="5" height="5" fill="#e6e600" />
      <rect x="55" y="20" width="5" height="5" fill="#e6e600" />
      <rect x="55" y="25" width="5" height="5" fill="#e6e600" />
      <rect x="55" y="30" width="5" height="5" fill="#e6e600" />
      <rect x="55" y="35" width="5" height="5" fill="#e6e600" />
      <rect x="55" y="40" width="5" height="5" fill="#ffff66" />
      <rect x="55" y="45" width="5" height="5" fill="#e6e600" />
      <rect x="55" y="50" width="5" height="5" fill="#333300" />
      <rect x="55" y="55" width="5" height="5" fill="#e6e600" />
      <rect x="55" y="60" width="5" height="5" fill="#333300" />
      <rect x="55" y="65" width="5" height="5" fill="#333300" />
      <rect x="55" y="70" width="5" height="5" fill="#e6e600" />
      <rect x="55" y="75" width="5" height="5" fill="#ffff66" />
      <rect x="55" y="80" width="5" height="5" fill="#ffff66" />
      <rect x="55" y="85" width="5" height="5" fill="#ffff66" />
      <rect x="55" y="90" width="5" height="5" fill="#e6e600" />
      <rect x="55" y="95" width="5" height="5" fill="#e6e600" />
      <rect x="55" y="100" width="5" height="5" fill="#333300" />
      <rect x="55" y="105" width="5" height="5" fill="#333300" />
      <rect x="60" y="5" width="5" height="5" fill="#333300" />
      <rect x="60" y="10" width="5" height="5" fill="#e6e600" />
      <rect x="60" y="15" width="5" height="5" fill="#e6e600" />
      <rect x="60" y="20" width="5" height="5" fill="#e6e600" />
      <rect x="60" y="25" width="5" height="5" fill="#333300" />
      <rect x="60" y="30" width="5" height="5" fill="#e6e600" />
      <rect x="60" y="35" width="5" height="5" fill="#ffff66" />
      <rect x="60" y="40" width="5" height="5" fill="#e6e600" />
      <rect x="60" y="45" width="5" height="5" fill="#e6e600" />
      <rect x="60" y="50" width="5" height="5" fill="#333300" />
      <rect x="60" y="55" width="5" height="5" fill="#e6e600" />
      <rect x="60" y="60" width="5" height="5" fill="#e6e600" />
      <rect x="60" y="65" width="5" height="5" fill="#e6e600" />
      <rect x="60" y="70" width="5" height="5" fill="#e6e600" />
      <rect x="60" y="75" width="5" height="5" fill="#e6e600" />
      <rect x="60" y="80" width="5" height="5" fill="#333300" />
      <rect x="60" y="85" width="5" height="5" fill="#333300" />
      <rect x="60" y="90" width="5" height="5" fill="#333300" />
      <rect x="60" y="95" width="5" height="5" fill="#333300" />
      <rect x="60" y="100" width="5" height="5" fill="#333300" />
      <rect x="65" y="0" width="5" height="5" fill="#333300" />
      <rect x="65" y="5" width="5" height="5" fill="#e6e600" />
      <rect x="65" y="10" width="5" height="5" fill="#ffbb99" />
      <rect x="65" y="15" width="5" height="5" fill="#e6e600" />
      <rect x="65" y="20" width="5" height="5" fill="#e6e600" />
      <rect x="65" y="25" width="5" height="5" fill="#e6e600" />
      <rect x="65" y="30" width="5" height="5" fill="#e6e600" />
      <rect x="65" y="35" width="5" height="5" fill="#e6e600" />
      <rect x="65" y="40" width="5" height="5" fill="#e6e600" />
      <rect x="65" y="45" width="5" height="5" fill="#e6e600" />
      <rect x="65" y="50" width="5" height="5" fill="#333300" />
      <rect x="65" y="55" width="5" height="5" fill="#e6e600" />
      <rect x="65" y="60" width="5" height="5" fill="#e6e600" />
      <rect x="65" y="65" width="5" height="5" fill="#333300" />
      <rect x="65" y="70" width="5" height="5" fill="#333300" />
      <rect x="65" y="75" width="5" height="5" fill="#333300" />
      <rect x="65" y="80" width="5" height="5" fill="#333300" />
      <rect x="70" y="5" width="5" height="5" fill="#333300" />
      <rect x="70" y="10" width="5" height="5" fill="#333300" />
      <rect x="70" y="15" width="5" height="5" fill="#333300" />
      <rect x="70" y="20" width="5" height="5" fill="#e6e600" />
      <rect x="70" y="25" width="5" height="5" fill="#e6e600" />
      <rect x="70" y="30" width="5" height="5" fill="#e6e600" />
      <rect x="70" y="35" width="5" height="5" fill="#e6e600" />
      <rect x="70" y="40" width="5" height="5" fill="#e6e600" />
      <rect x="70" y="45" width="5" height="5" fill="#333300" />
      <rect x="70" y="50" width="5" height="5" fill="#e6e600" />
      <rect x="70" y="55" width="5" height="5" fill="#e6e600" />
      <rect x="70" y="60" width="5" height="5" fill="#333300" />
      <rect x="70" y="65" width="5" height="5" fill="#333300" />
      <rect x="75" y="15" width="5" height="5" fill="#333300" />
      <rect x="75" y="20" width="5" height="5" fill="#333300" />
      <rect x="75" y="25" width="5" height="5" fill="#333300" />
      <rect x="75" y="30" width="5" height="5" fill="#333300" />
      <rect x="75" y="35" width="5" height="5" fill="#333300" />
      <rect x="75" y="40" width="5" height="5" fill="#333300" />
      <rect x="75" y="50" width="5" height="5" fill="#333300" />
      <rect x="75" y="55" width="5" height="5" fill="#ffbb99" />
      <rect x="75" y="60" width="5" height="5" fill="#333300" />
      <rect x="80" y="50" width="5" height="5" fill="#333300" />
      <rect x="80" y="55" width="5" height="5" fill="#333300" />
      <rect x="80" y="60" width="5" height="5" fill="#333300" />
    </SvgIcon>
  );
}

export function MoonCatIcon2() {
  return (
    <SvgIcon viewBox="0 0 100 105">
      <rect x="0" y="40" width="5" height="5" fill="#555555" />
      <rect x="0" y="45" width="5" height="5" fill="#555555" />
      <rect x="0" y="50" width="5" height="5" fill="#555555" />
      <rect x="0" y="55" width="5" height="5" fill="#555555" />
      <rect x="0" y="60" width="5" height="5" fill="#555555" />
      <rect x="0" y="65" width="5" height="5" fill="#555555" />
      <rect x="0" y="70" width="5" height="5" fill="#555555" />
      <rect x="0" y="90" width="5" height="5" fill="#555555" />
      <rect x="0" y="95" width="5" height="5" fill="#555555" />
      <rect x="0" y="100" width="5" height="5" fill="#555555" />
      <rect x="5" y="35" width="5" height="5" fill="#555555" />
      <rect x="5" y="40" width="5" height="5" fill="#ffffff" />
      <rect x="5" y="45" width="5" height="5" fill="#ffffff" />
      <rect x="5" y="50" width="5" height="5" fill="#ffffff" />
      <rect x="5" y="55" width="5" height="5" fill="#d3d3d3" />
      <rect x="5" y="60" width="5" height="5" fill="#ffffff" />
      <rect x="5" y="65" width="5" height="5" fill="#d3d3d3" />
      <rect x="5" y="70" width="5" height="5" fill="#ffffff" />
      <rect x="5" y="75" width="5" height="5" fill="#555555" />
      <rect x="5" y="85" width="5" height="5" fill="#555555" />
      <rect x="5" y="90" width="5" height="5" fill="#555555" />
      <rect x="5" y="95" width="5" height="5" fill="#ff9999" />
      <rect x="5" y="100" width="5" height="5" fill="#555555" />
      <rect x="10" y="30" width="5" height="5" fill="#555555" />
      <rect x="10" y="35" width="5" height="5" fill="#ffffff" />
      <rect x="10" y="40" width="5" height="5" fill="#ff9999" />
      <rect x="10" y="45" width="5" height="5" fill="#ffffff" />
      <rect x="10" y="50" width="5" height="5" fill="#ffffff" />
      <rect x="10" y="55" width="5" height="5" fill="#ffffff" />
      <rect x="10" y="60" width="5" height="5" fill="#ffffff" />
      <rect x="10" y="65" width="5" height="5" fill="#aaaaaa" />
      <rect x="10" y="70" width="5" height="5" fill="#ffffff" />
      <rect x="10" y="75" width="5" height="5" fill="#ffffff" />
      <rect x="10" y="80" width="5" height="5" fill="#555555" />
      <rect x="10" y="85" width="5" height="5" fill="#555555" />
      <rect x="10" y="90" width="5" height="5" fill="#ffffff" />
      <rect x="10" y="95" width="5" height="5" fill="#ffffff" />
      <rect x="10" y="100" width="5" height="5" fill="#555555" />
      <rect x="15" y="30" width="5" height="5" fill="#555555" />
      <rect x="15" y="35" width="5" height="5" fill="#555555" />
      <rect x="15" y="40" width="5" height="5" fill="#ffffff" />
      <rect x="15" y="45" width="5" height="5" fill="#ffffff" />
      <rect x="15" y="50" width="5" height="5" fill="#ffffff" />
      <rect x="15" y="55" width="5" height="5" fill="#555555" />
      <rect x="15" y="60" width="5" height="5" fill="#ffffff" />
      <rect x="15" y="65" width="5" height="5" fill="#aaaaaa" />
      <rect x="15" y="70" width="5" height="5" fill="#ffffff" />
      <rect x="15" y="75" width="5" height="5" fill="#ffffff" />
      <rect x="15" y="80" width="5" height="5" fill="#555555" />
      <rect x="15" y="85" width="5" height="5" fill="#ffffff" />
      <rect x="15" y="90" width="5" height="5" fill="#d3d3d3" />
      <rect x="15" y="95" width="5" height="5" fill="#555555" />
      <rect x="15" y="100" width="5" height="5" fill="#555555" />
      <rect x="20" y="40" width="5" height="5" fill="#555555" />
      <rect x="20" y="45" width="5" height="5" fill="#d3d3d3" />
      <rect x="20" y="50" width="5" height="5" fill="#ffffff" />
      <rect x="20" y="55" width="5" height="5" fill="#ffffff" />
      <rect x="20" y="60" width="5" height="5" fill="#ffffff" />
      <rect x="20" y="65" width="5" height="5" fill="#ffffff" />
      <rect x="20" y="70" width="5" height="5" fill="#ffffff" />
      <rect x="20" y="75" width="5" height="5" fill="#ffffff" />
      <rect x="20" y="80" width="5" height="5" fill="#555555" />
      <rect x="20" y="85" width="5" height="5" fill="#ffffff" />
      <rect x="20" y="90" width="5" height="5" fill="#ffffff" />
      <rect x="20" y="95" width="5" height="5" fill="#555555" />
      <rect x="25" y="40" width="5" height="5" fill="#555555" />
      <rect x="25" y="45" width="5" height="5" fill="#ffffff" />
      <rect x="25" y="50" width="5" height="5" fill="#ffffff" />
      <rect x="25" y="55" width="5" height="5" fill="#ffffff" />
      <rect x="25" y="60" width="5" height="5" fill="#ffffff" />
      <rect x="25" y="65" width="5" height="5" fill="#ff9999" />
      <rect x="25" y="70" width="5" height="5" fill="#ffffff" />
      <rect x="25" y="75" width="5" height="5" fill="#ffffff" />
      <rect x="25" y="80" width="5" height="5" fill="#555555" />
      <rect x="25" y="85" width="5" height="5" fill="#ffffff" />
      <rect x="25" y="90" width="5" height="5" fill="#555555" />
      <rect x="25" y="95" width="5" height="5" fill="#555555" />
      <rect x="25" y="100" width="5" height="5" fill="#555555" />
      <rect x="30" y="40" width="5" height="5" fill="#555555" />
      <rect x="30" y="45" width="5" height="5" fill="#d3d3d3" />
      <rect x="30" y="50" width="5" height="5" fill="#ffffff" />
      <rect x="30" y="55" width="5" height="5" fill="#ffffff" />
      <rect x="30" y="60" width="5" height="5" fill="#ffffff" />
      <rect x="30" y="65" width="5" height="5" fill="#ffffff" />
      <rect x="30" y="70" width="5" height="5" fill="#ffffff" />
      <rect x="30" y="75" width="5" height="5" fill="#ffffff" />
      <rect x="30" y="80" width="5" height="5" fill="#555555" />
      <rect x="30" y="85" width="5" height="5" fill="#ffffff" />
      <rect x="30" y="90" width="5" height="5" fill="#ffffff" />
      <rect x="30" y="95" width="5" height="5" fill="#ff9999" />
      <rect x="30" y="100" width="5" height="5" fill="#555555" />
      <rect x="35" y="35" width="5" height="5" fill="#555555" />
      <rect x="35" y="40" width="5" height="5" fill="#ffffff" />
      <rect x="35" y="45" width="5" height="5" fill="#ffffff" />
      <rect x="35" y="50" width="5" height="5" fill="#ffffff" />
      <rect x="35" y="55" width="5" height="5" fill="#555555" />
      <rect x="35" y="60" width="5" height="5" fill="#ffffff" />
      <rect x="35" y="65" width="5" height="5" fill="#aaaaaa" />
      <rect x="35" y="70" width="5" height="5" fill="#ffffff" />
      <rect x="35" y="75" width="5" height="5" fill="#ffffff" />
      <rect x="35" y="80" width="5" height="5" fill="#555555" />
      <rect x="35" y="85" width="5" height="5" fill="#ffffff" />
      <rect x="35" y="90" width="5" height="5" fill="#d3d3d3" />
      <rect x="35" y="95" width="5" height="5" fill="#555555" />
      <rect x="35" y="100" width="5" height="5" fill="#555555" />
      <rect x="40" y="30" width="5" height="5" fill="#555555" />
      <rect x="40" y="35" width="5" height="5" fill="#ffffff" />
      <rect x="40" y="40" width="5" height="5" fill="#ff9999" />
      <rect x="40" y="45" width="5" height="5" fill="#ffffff" />
      <rect x="40" y="50" width="5" height="5" fill="#ffffff" />
      <rect x="40" y="55" width="5" height="5" fill="#ffffff" />
      <rect x="40" y="60" width="5" height="5" fill="#ffffff" />
      <rect x="40" y="65" width="5" height="5" fill="#aaaaaa" />
      <rect x="40" y="70" width="5" height="5" fill="#ffffff" />
      <rect x="40" y="75" width="5" height="5" fill="#ffffff" />
      <rect x="40" y="80" width="5" height="5" fill="#555555" />
      <rect x="40" y="85" width="5" height="5" fill="#ffffff" />
      <rect x="40" y="90" width="5" height="5" fill="#555555" />
      <rect x="40" y="95" width="5" height="5" fill="#555555" />
      <rect x="45" y="30" width="5" height="5" fill="#555555" />
      <rect x="45" y="35" width="5" height="5" fill="#555555" />
      <rect x="45" y="40" width="5" height="5" fill="#ffffff" />
      <rect x="45" y="45" width="5" height="5" fill="#ffffff" />
      <rect x="45" y="50" width="5" height="5" fill="#ffffff" />
      <rect x="45" y="55" width="5" height="5" fill="#d3d3d3" />
      <rect x="45" y="60" width="5" height="5" fill="#ffffff" />
      <rect x="45" y="65" width="5" height="5" fill="#d3d3d3" />
      <rect x="45" y="70" width="5" height="5" fill="#ffffff" />
      <rect x="45" y="75" width="5" height="5" fill="#555555" />
      <rect x="45" y="80" width="5" height="5" fill="#ffffff" />
      <rect x="45" y="85" width="5" height="5" fill="#ffffff" />
      <rect x="45" y="90" width="5" height="5" fill="#555555" />
      <rect x="50" y="0" width="5" height="5" fill="#555555" />
      <rect x="50" y="5" width="5" height="5" fill="#555555" />
      <rect x="50" y="10" width="5" height="5" fill="#555555" />
      <rect x="50" y="15" width="5" height="5" fill="#555555" />
      <rect x="50" y="40" width="5" height="5" fill="#555555" />
      <rect x="50" y="45" width="5" height="5" fill="#555555" />
      <rect x="50" y="50" width="5" height="5" fill="#555555" />
      <rect x="50" y="55" width="5" height="5" fill="#555555" />
      <rect x="50" y="60" width="5" height="5" fill="#555555" />
      <rect x="50" y="65" width="5" height="5" fill="#555555" />
      <rect x="50" y="70" width="5" height="5" fill="#555555" />
      <rect x="50" y="75" width="5" height="5" fill="#555555" />
      <rect x="50" y="80" width="5" height="5" fill="#ffffff" />
      <rect x="50" y="85" width="5" height="5" fill="#555555" />
      <rect x="50" y="90" width="5" height="5" fill="#555555" />
      <rect x="55" y="0" width="5" height="5" fill="#555555" />
      <rect x="55" y="5" width="5" height="5" fill="#ffffff" />
      <rect x="55" y="10" width="5" height="5" fill="#ffffff" />
      <rect x="55" y="15" width="5" height="5" fill="#555555" />
      <rect x="55" y="40" width="5" height="5" fill="#555555" />
      <rect x="55" y="45" width="5" height="5" fill="#555555" />
      <rect x="55" y="50" width="5" height="5" fill="#ffffff" />
      <rect x="55" y="55" width="5" height="5" fill="#ffffff" />
      <rect x="55" y="60" width="5" height="5" fill="#ffffff" />
      <rect x="55" y="65" width="5" height="5" fill="#ffffff" />
      <rect x="55" y="70" width="5" height="5" fill="#ffffff" />
      <rect x="55" y="75" width="5" height="5" fill="#ffffff" />
      <rect x="55" y="80" width="5" height="5" fill="#ffffff" />
      <rect x="55" y="85" width="5" height="5" fill="#555555" />
      <rect x="55" y="90" width="5" height="5" fill="#555555" />
      <rect x="55" y="95" width="5" height="5" fill="#555555" />
      <rect x="60" y="0" width="5" height="5" fill="#555555" />
      <rect x="60" y="5" width="5" height="5" fill="#d3d3d3" />
      <rect x="60" y="10" width="5" height="5" fill="#555555" />
      <rect x="60" y="35" width="5" height="5" fill="#555555" />
      <rect x="60" y="40" width="5" height="5" fill="#555555" />
      <rect x="60" y="45" width="5" height="5" fill="#d3d3d3" />
      <rect x="60" y="50" width="5" height="5" fill="#ffffff" />
      <rect x="60" y="55" width="5" height="5" fill="#ffffff" />
      <rect x="60" y="60" width="5" height="5" fill="#ffffff" />
      <rect x="60" y="65" width="5" height="5" fill="#ffffff" />
      <rect x="60" y="70" width="5" height="5" fill="#ffffff" />
      <rect x="60" y="75" width="5" height="5" fill="#aaaaaa" />
      <rect x="60" y="80" width="5" height="5" fill="#555555" />
      <rect x="60" y="85" width="5" height="5" fill="#555555" />
      <rect x="60" y="90" width="5" height="5" fill="#ff9999" />
      <rect x="60" y="95" width="5" height="5" fill="#555555" />
      <rect x="65" y="0" width="5" height="5" fill="#555555" />
      <rect x="65" y="5" width="5" height="5" fill="#ffffff" />
      <rect x="65" y="10" width="5" height="5" fill="#555555" />
      <rect x="65" y="30" width="5" height="5" fill="#555555" />
      <rect x="65" y="35" width="5" height="5" fill="#555555" />
      <rect x="65" y="40" width="5" height="5" fill="#ffffff" />
      <rect x="65" y="45" width="5" height="5" fill="#ffffff" />
      <rect x="65" y="50" width="5" height="5" fill="#d3d3d3" />
      <rect x="65" y="55" width="5" height="5" fill="#ffffff" />
      <rect x="65" y="60" width="5" height="5" fill="#ffffff" />
      <rect x="65" y="65" width="5" height="5" fill="#ffffff" />
      <rect x="65" y="70" width="5" height="5" fill="#aaaaaa" />
      <rect x="65" y="75" width="5" height="5" fill="#aaaaaa" />
      <rect x="65" y="80" width="5" height="5" fill="#555555" />
      <rect x="65" y="85" width="5" height="5" fill="#ffffff" />
      <rect x="65" y="90" width="5" height="5" fill="#ffffff" />
      <rect x="65" y="95" width="5" height="5" fill="#555555" />
      <rect x="70" y="0" width="5" height="5" fill="#555555" />
      <rect x="70" y="5" width="5" height="5" fill="#d3d3d3" />
      <rect x="70" y="10" width="5" height="5" fill="#d3d3d3" />
      <rect x="70" y="15" width="5" height="5" fill="#555555" />
      <rect x="70" y="30" width="5" height="5" fill="#555555" />
      <rect x="70" y="35" width="5" height="5" fill="#ffffff" />
      <rect x="70" y="40" width="5" height="5" fill="#d3d3d3" />
      <rect x="70" y="45" width="5" height="5" fill="#ffffff" />
      <rect x="70" y="50" width="5" height="5" fill="#ffffff" />
      <rect x="70" y="55" width="5" height="5" fill="#ffffff" />
      <rect x="70" y="60" width="5" height="5" fill="#ffffff" />
      <rect x="70" y="65" width="5" height="5" fill="#ffffff" />
      <rect x="70" y="70" width="5" height="5" fill="#aaaaaa" />
      <rect x="70" y="75" width="5" height="5" fill="#aaaaaa" />
      <rect x="70" y="80" width="5" height="5" fill="#555555" />
      <rect x="70" y="85" width="5" height="5" fill="#ffffff" />
      <rect x="70" y="90" width="5" height="5" fill="#555555" />
      <rect x="70" y="95" width="5" height="5" fill="#555555" />
      <rect x="70" y="100" width="5" height="5" fill="#555555" />
      <rect x="75" y="0" width="5" height="5" fill="#555555" />
      <rect x="75" y="5" width="5" height="5" fill="#555555" />
      <rect x="75" y="10" width="5" height="5" fill="#ffffff" />
      <rect x="75" y="15" width="5" height="5" fill="#555555" />
      <rect x="75" y="20" width="5" height="5" fill="#555555" />
      <rect x="75" y="25" width="5" height="5" fill="#555555" />
      <rect x="75" y="30" width="5" height="5" fill="#555555" />
      <rect x="75" y="35" width="5" height="5" fill="#ffffff" />
      <rect x="75" y="40" width="5" height="5" fill="#ffffff" />
      <rect x="75" y="45" width="5" height="5" fill="#d3d3d3" />
      <rect x="75" y="50" width="5" height="5" fill="#ffffff" />
      <rect x="75" y="55" width="5" height="5" fill="#ffffff" />
      <rect x="75" y="60" width="5" height="5" fill="#ffffff" />
      <rect x="75" y="65" width="5" height="5" fill="#aaaaaa" />
      <rect x="75" y="70" width="5" height="5" fill="#aaaaaa" />
      <rect x="75" y="75" width="5" height="5" fill="#555555" />
      <rect x="75" y="80" width="5" height="5" fill="#555555" />
      <rect x="75" y="85" width="5" height="5" fill="#555555" />
      <rect x="75" y="90" width="5" height="5" fill="#555555" />
      <rect x="75" y="95" width="5" height="5" fill="#ff9999" />
      <rect x="75" y="100" width="5" height="5" fill="#555555" />
      <rect x="80" y="5" width="5" height="5" fill="#555555" />
      <rect x="80" y="10" width="5" height="5" fill="#d3d3d3" />
      <rect x="80" y="15" width="5" height="5" fill="#ffffff" />
      <rect x="80" y="20" width="5" height="5" fill="#d3d3d3" />
      <rect x="80" y="25" width="5" height="5" fill="#ffffff" />
      <rect x="80" y="30" width="5" height="5" fill="#555555" />
      <rect x="80" y="35" width="5" height="5" fill="#ffffff" />
      <rect x="80" y="40" width="5" height="5" fill="#ffffff" />
      <rect x="80" y="45" width="5" height="5" fill="#ffffff" />
      <rect x="80" y="50" width="5" height="5" fill="#ffffff" />
      <rect x="80" y="55" width="5" height="5" fill="#ffffff" />
      <rect x="80" y="60" width="5" height="5" fill="#ffffff" />
      <rect x="80" y="65" width="5" height="5" fill="#ffffff" />
      <rect x="80" y="70" width="5" height="5" fill="#ffffff" />
      <rect x="80" y="75" width="5" height="5" fill="#ffffff" />
      <rect x="80" y="80" width="5" height="5" fill="#555555" />
      <rect x="80" y="85" width="5" height="5" fill="#555555" />
      <rect x="80" y="90" width="5" height="5" fill="#ffffff" />
      <rect x="80" y="95" width="5" height="5" fill="#ffffff" />
      <rect x="80" y="100" width="5" height="5" fill="#555555" />
      <rect x="85" y="5" width="5" height="5" fill="#555555" />
      <rect x="85" y="10" width="5" height="5" fill="#555555" />
      <rect x="85" y="15" width="5" height="5" fill="#555555" />
      <rect x="85" y="20" width="5" height="5" fill="#555555" />
      <rect x="85" y="25" width="5" height="5" fill="#ffffff" />
      <rect x="85" y="30" width="5" height="5" fill="#555555" />
      <rect x="85" y="35" width="5" height="5" fill="#ffffff" />
      <rect x="85" y="40" width="5" height="5" fill="#ffffff" />
      <rect x="85" y="45" width="5" height="5" fill="#ffffff" />
      <rect x="85" y="50" width="5" height="5" fill="#ffffff" />
      <rect x="85" y="55" width="5" height="5" fill="#ffffff" />
      <rect x="85" y="60" width="5" height="5" fill="#ffffff" />
      <rect x="85" y="65" width="5" height="5" fill="#ffffff" />
      <rect x="85" y="70" width="5" height="5" fill="#ffffff" />
      <rect x="85" y="75" width="5" height="5" fill="#ffffff" />
      <rect x="85" y="80" width="5" height="5" fill="#ffffff" />
      <rect x="85" y="85" width="5" height="5" fill="#ffffff" />
      <rect x="85" y="90" width="5" height="5" fill="#ffffff" />
      <rect x="85" y="95" width="5" height="5" fill="#555555" />
      <rect x="85" y="100" width="5" height="5" fill="#555555" />
      <rect x="90" y="20" width="5" height="5" fill="#555555" />
      <rect x="90" y="25" width="5" height="5" fill="#555555" />
      <rect x="90" y="30" width="5" height="5" fill="#555555" />
      <rect x="90" y="35" width="5" height="5" fill="#555555" />
      <rect x="90" y="40" width="5" height="5" fill="#ffffff" />
      <rect x="90" y="45" width="5" height="5" fill="#ffffff" />
      <rect x="90" y="50" width="5" height="5" fill="#ffffff" />
      <rect x="90" y="55" width="5" height="5" fill="#555555" />
      <rect x="90" y="60" width="5" height="5" fill="#555555" />
      <rect x="90" y="65" width="5" height="5" fill="#555555" />
      <rect x="90" y="70" width="5" height="5" fill="#555555" />
      <rect x="90" y="75" width="5" height="5" fill="#d3d3d3" />
      <rect x="90" y="80" width="5" height="5" fill="#ffffff" />
      <rect x="90" y="85" width="5" height="5" fill="#555555" />
      <rect x="90" y="90" width="5" height="5" fill="#555555" />
      <rect x="90" y="95" width="5" height="5" fill="#555555" />
      <rect x="95" y="35" width="5" height="5" fill="#555555" />
      <rect x="95" y="40" width="5" height="5" fill="#555555" />
      <rect x="95" y="45" width="5" height="5" fill="#555555" />
      <rect x="95" y="50" width="5" height="5" fill="#555555" />
      <rect x="95" y="55" width="5" height="5" fill="#555555" />
      <rect x="95" y="75" width="5" height="5" fill="#555555" />
      <rect x="95" y="80" width="5" height="5" fill="#555555" />
      <rect x="95" y="85" width="5" height="5" fill="#555555" />
    </SvgIcon>
  );
}
