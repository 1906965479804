/* eslint-disable import/no-webpack-loader-syntax */
import * as Comlink from "comlink";
import ScannerWorker from "worker-loader!./worker";

interface Scanner {
  scan(searchSeed: string): Promise<string>;
}

export function scan(
  worker: ScannerWorker,
  searchSeed: string,
): Promise<string> {
  return Comlink.wrap<Scanner>(worker).scan(searchSeed);
}

export { ScannerWorker };
